<template>
  <view-homepage />
</template>

<script setup>
import { onMounted } from 'vue';
import { useHead } from '#imports';
import { useStore } from 'vuex';
import { useIntranet } from '@/composables/useIntranet';
import { getMetadataAndTags } from '../helpers/metadata';
import ViewHomepage from '../components/ViewHomepage.vue';
import { clientWorkWindowEvent } from '../helpers/event-dispatch-helpers';

const store = useStore();
const metadata = getMetadataAndTags(store.state.settings.metadata, 'homepage', 'Home');
const { handleLayout } = useIntranet();

useHead({
  ...metadata.metaTitle,
  meta: metadata.metaTags,
});

onMounted(() => {
  handleLayout();
  clientWorkWindowEvent('homepage');
});
</script>
